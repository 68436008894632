#websites {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	margin-bottom: 10rem;
	scroll-margin-top: 100px;

	.website {
		padding: 1rem;
		height: 10rem;
		border-radius: 1rem;
		width: 80%;
		max-width: 40rem;
		display: flex;
		background-color: #1d1d1d;
		border: 2px solid #ffffff0d;
		outline: 0px solid transparent;
		transition: all 0.3s ease-in-out;
		outline-offset: 20px;
		text-decoration: none;
		color: white;

		&:hover {
			border: 2px solid transparent;
			outline: 2px solid #ffffff5a;
			outline-offset: 8px;
		}

		.website__icon {
			margin-left: -3rem;
			border-radius: 16px;
			overflow: hidden;
			height: 5rem;
			width: 5rem;
			z-index: 1;

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.website__details {
			display: flex;
			flex-direction: column;
			margin-left: 2rem;
			padding-right: 3rem;
			flex: 1;
			justify-content: space-between;

			.website__details-text {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;

				.website__name {
					font-size: 1.5rem;
				}

				.website__description {
					font-size: 0.8rem;
					color: #747474;
				}
			}

			.website__techs {
				display: flex;
				gap: 0.5rem;

				.website__tech-icon {
					font-size: 0.8rem;
					border-radius: 1rem;
					padding: 0.2rem 0.8rem;
					background-color: #ffffff40;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#websites {
		.website {
			margin-top: 3rem;
			height: auto !important;
			flex-direction: column;

			.website__icon {
				height: 4rem;
				width: 4rem;
				margin-top: -3rem;
				margin-left: 0;
			}

			.website__details {
				margin-left: 0;
				padding-right: 0;

				.website__details-text {
					margin: 1rem 0;
				}

				.website__techs {
					display: flex;
					flex-wrap: wrap;

					.website__tech-icon {
						font-size: 0.6rem;
						padding: 0.1rem 0.5rem;
					}
				}
			}
		}
	}
}
