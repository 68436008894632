#socials {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-bottom: 10rem;

	a {
		color: white;
		text-decoration: none;
	}
}
