.navigation {
	width: calc(34px + 1rem);
	padding: 0.8rem;
	justify-content: flex-end;
	transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);

	.menu {
		position: relative;
		height: calc(100% - 1rem);
		border-radius: 8px;
		background-color: #1a1a1aa3;

		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 0.5rem;

		div {
			position: absolute;
			display: flex;
			justify-content: flex-end;
			width: calc(100% - 1rem);
			bottom: 0.5rem;
		}

		.link {
			cursor: pointer;
			height: 18px;
			overflow: hidden;
			text-decoration: none;
			color: white;
			padding: 0.5rem;
			border-radius: 6px;
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			gap: 1rem;

			.link__icon {
				display: flex;
			}
		}

		.link.active,
		.link:hover {
			background-color: #1f1f1f;
		}

		button {
			cursor: pointer;
			height: 34px;
			width: 34px;
			overflow: hidden;
			text-decoration: none;
			color: white;
			padding: 0.5rem;
			border-radius: 6px;
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			border: none;
			outline: none;
			background-color: transparent;
			color: white;
		}

		button > svg {
			transition: all 0.4s ease-in-out;
		}

		button:hover {
			background-color: #1f1f1f;
		}
	}
}

.navigation.open {
	width: 12rem;

	.link {
		padding: 0.5rem;
		justify-content: flex-start;
		.link__label {
			display: block;
		}
	}

	button > svg {
		transform: rotate(180deg);
	}
}

@media screen and (max-width: 768px) {
	.navigation {
		display: none;
	}
}
