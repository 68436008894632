#me {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	scroll-margin-top: 100px;

	h1 {
		font-size: 4rem;
		color: white;
	}

	p {
		display: flex;
		gap: 0.3rem;
	}

	a {
		position: relative;
		text-decoration: none;
		color: #747474;

		svg {
			height: 14px;
			position: absolute;
			transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
			opacity: 0;
			transform: translate(-35px, -10px) rotate(-45deg);
		}

		&:hover svg {
			opacity: 1;
			transform: translate(-13px, -10px) rotate(0deg);
		}
	}

	span {
		color: #747474;
	}
}

@media screen and (max-width: 768px) {
	#me {
		gap: 0.5rem;

		h1 {
			text-align: center;
			font-size: 2rem;
		}

		p {
			font-size: 0.6rem;
			width: 80%;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
	}
}
