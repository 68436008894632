@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::after,
*::before {
	padding: 0;
	margin: 0;
}

html {
	font-size: 16px;
}

html,
body,
#root {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	font-family: 'Poppins', Verdana, sans-serif;
	color: white;
	/* animation: gradientAnimation 10s ease infinite; */
	/* background: linear-gradient(45deg, #000000, #0d0d0d, #1a1a1a, #0d0d0d); */
	/* background-size: 400% 400%; */
	/* transition: all 1s ease; */
	background-image: radial-gradient(
			circle at center center,
			transparent 0%,
			rgb(0, 0, 0) 99%
		),
		repeating-linear-gradient(
			0deg,
			rgba(79, 79, 79, 0.2) 0px,
			rgba(79, 79, 79, 0.2) 1px,
			transparent 1px,
			transparent 6px
		),
		repeating-linear-gradient(
			90deg,
			rgba(79, 79, 79, 0.2) 0px,
			rgba(79, 79, 79, 0.2) 1px,
			transparent 1px,
			transparent 6px
		),
		linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0));
}

#root {
	display: flex;
}

.content {
	overflow: auto;
	scroll-behavior: smooth;
}

.title {
	font-size: 2rem;
}

.content {
	flex: 1;
}

@media screen and (max-width: 768px) {
	.html {
		font-size: 10px;
	}
}
